import { graphql } from 'gatsby'
import { LayoutTailwindDefault } from '@/layouts/layout-tailwind-default'
import { Seo } from '@/components/tailwind/seo'

import { ProductFilteredList } from '@/components/tailwind/product-filtered-list'

export const ProductTypeIndex = props => {
  return (
    <LayoutTailwindDefault>
      <Seo title={`Category: ${props.pageContext.productType}`} />
      <ProductFilteredList {...props} />
    </LayoutTailwindDefault>
  )
}

export default ProductTypeIndex

export const query = graphql`
  query($productType: String!) {
    products: allShopifyProduct(
      filter: { productType: { eq: $productType } }
      sort: { fields: publishedAt, order: ASC }
      limit: 24
    ) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
